<!-- 首页-新闻资讯 -->
<template>
	<div class="NewsInfo-warp">
		<div class="title">
			<span class="title-text">新闻资讯</span>
			<span class="title-desc" @click="handleMore"
				>更多动态<i class="iconfont icon-xiala-shang-copy"></i
			></span>
		</div>
		<div class="NewsInfo-content" v-if="!isMobileEnd">
			<div class="card" v-for="i in cardList" :key="i.id">
				<div class="card-img" @click="getNewsAndTrends(i.id)">
					<img v-lazy="i.bgImg" :key="i.id" alt="新闻简述图" />
					<div class="card-img-title">
						<span>{{ i.title }}</span>
					</div>
				</div>
				<div class="card-bottom" @click="getNewsAndTrends(i.id)">
					{{ i.sketch }}
				</div>
			</div>
		</div>
		<div
			class="NewsInfo-768-content"
			v-else
			v-lazy:background-image="containerBgUrl"
		>
			<div class="card" v-for="i in cardList" :key="i.id">
				<div class="card-content">
					<div class="card-content-title">{{ i.title }}</div>
					<div class="card-content-sketch">
						{{ i.sketch }}
					</div>
					<div
						class="card-content-btn"
						@click="getNewsAndTrends(i.id)"
					>
						查看更多
					</div>
				</div>
				<div class="card-img">
					<img v-lazy="i.bgImg" :key="i.id" alt="新闻简述图" />
				</div>
				<div class="card-bar"></div>
			</div>
		</div>
	</div>
</template>

<script>
import { getHomeNewsList } from '@/network/home/index'
export default {
	name: 'NewsInfo',
	data() {
		return {
			cardList: [],
			containerBgUrl: require('@/assets/img/containerBg.png'),
		}
	},
	watch: {},
	created() {
		this.j_getHomeNewsList() //获取新闻
	},
	mounted() {},
	methods: {
		handleMore() {
			this.$router.push('/newsAndTrends')
		},
		//获取新闻列表
		j_getHomeNewsList() {
			return new Promise((resolve, reject) => {
				getHomeNewsList().then((res) => {
					if (res.data.code === 200) {
						let list = res.data.data.list
						this.cardList = list.map((item) => {
							let obj = Object.assign(item, {
								bgImg: window.baseUrl + item.bgImg,
							})
							return obj
						})
						resolve()
					} else {
						this.$message.warning(res.data.msg)
						reject()
					}
				})
			})
		},
		getNewsAndTrends(id) {
			if (id) {
				this.$router.push({
					path: '/newsDetails',
					query: {
						id,
					},
				})
			}
		},
	},
	components: {},
	computed: {
		isMobileEnd() {
			return this.$store.state.isMobileEnd
		},
	},

	destroyed() {},
}
</script>
<style lang="scss" scoped>
.NewsInfo-warp {
	margin-bottom: 1.6rem;
	.NewsInfo-content {
		width: 100%;
		min-height: 3.02rem;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-auto-flow: row;
		justify-content: space-around;
		.card {
			width: 4.1rem;
			height: 3rem;
			overflow: hidden;
			.card-img {
				width: 100%;
				height: 2.3rem;
				margin-bottom: -0.05rem;
				position: relative;
				cursor: pointer;
				img {
					width: 100%;
					height: 100%;
					display: inline-block;
					object-fit: cover;
				}
				.card-img-title {
					width: 100%;
					height: 0.6rem;
					background: linear-gradient(
						180deg,
						rgba(0, 0, 0, 0) 0%,
						rgba(0, 0, 0, 0.6) 100%
					);
					line-height: 0.6rem;
					position: absolute;
					bottom: 0;
					padding: 0.16rem 0 0 0.12rem;
					span {
						width: calc(100% - 0.24rem);
						font-size: 0.18rem;
						font-family: Source Han Sans, Source Han Sans CN;
						font-weight: 700;
						color: #ffffff;
						display: inline-block;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
			}
			.card-bottom {
				text-align: left;
				font-size: 0.16rem;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 400;
				color: #333333;
				line-height: 0.24rem;
				margin-top: 0.24rem;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
			&:hover {
				.card-bottom {
					color: #127bf2;
					cursor: pointer;
				}
			}
		}
	}
	.NewsInfo-768-content {
		width: 343px;
		height: 305px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		overflow: hidden;
		.card {
			padding: 12px;
			display: flex;
			justify-content: space-between;
			position: relative;
			margin-bottom: 1px;
			.card-content {
				width: 170px;
				&-title {
					font-size: 16px;
					font-family: Source Han Sans, Source Han Sans CN;
					font-weight: 500;
					color: #2d3340;
					line-height: 16px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				&-sketch {
					font-size: 12px;
					font-family: Source Han Sans, Source Han Sans CN;
					font-weight: 400;
					color: #656b74;
					line-height: 12px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					margin: 12px 0 18px;
				}
				&-btn {
					font-size: 12px;
					font-family: Source Han Sans, Source Han Sans CN;
					font-weight: 400;
					color: #127bf2;
					line-height: 20px;
				}
			}
			.card-img {
				width: 137px;
				height: 77px;
				border-radius: 4px 4px 4px 4px;
				overflow: hidden;
			}
			.card-bar {
				width: 182px;
				height: 1px;
				background: #e6e7ee;
				position: absolute;
				bottom: -1px;
			}
		}
	}
}
.title {
	width: 100%;
	margin-bottom: 0.6rem;
	text-align: center;
	.title-text {
		height: 0.6rem;
		font-size: 0.52rem;
		font-family: Source Han Sans, Source Han Sans CN;
		font-weight: 500;
		color: #333333;
		line-height: 0.6rem;
		display: block;
	}
	.title-desc {
		font-size: 0.16rem;
		font-family: Source Han Sans, Source Han Sans CN;
		font-weight: 400;
		color: #2185ff;
		line-height: 0.22rem;
		display: inline-block;
		padding-top: 0.24rem;
		cursor: pointer;
		i {
			font-size: 0.16rem;
			display: inline-block;
			margin-left: 0.04rem;
		}
	}
}

@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 992px) {
}
@media screen and (max-width: 768px) {
	.NewsInfo-warp {
		.title {
			.title-text {
				height: 24px;
				font-size: 16px;
				font-weight: 400;
				color: #020814;
				line-height: 24px;
			}
			.title-desc {
				font-size: 10px;
				line-height: 18px;
				i {
					font-size: 10px;
				}
			}
		}
	}
}
@media screen and (max-width: 520px) {
}
</style>
