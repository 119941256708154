<template>
	<div class="home">
		<div class="home-top-img">
			<home-swiper @handleTop="handleTop" />
		</div>
		<div class="container">
			<div class="center">
				<div
					class="containerBg"
					v-lazy:background-image="containerBgUrl"
				></div>
				<div class="inner">
					<industry-solutions />
					<product-center />
					<experience-center />
					<news-info />
					<company-profile />
				</div>
			</div>
			<div class="left"></div>
			<div class="right"></div>
		</div>
	</div>
</template>

<script>
import HomeSwiper from './childCpn/HomeSwiper'
import IndustrySolutions from './childCpn/IndustrySolutions'
import ProductCenter from './childCpn/ProductCenter'
import ExperienceCenter from './childCpn/ExperienceCenter'
import NewsInfo from './childCpn/NewsInfo'
import CompanyProfile from './childCpn/CompanyProfile'
export default {
	name: 'Home',
	data() {
		return {
			containerBgUrl: require('@/assets/img/containerBg.png'),
		}
	},
	watch: {},
	created() {},
	methods: {
		handleTop(path) {
			if (path) {
				this.$router.push(path)
			}
		},
	},
	components: {
		HomeSwiper,
		IndustrySolutions,
		ProductCenter,
		ExperienceCenter,
		NewsInfo,
		CompanyProfile,
	},
	computed: {},
	mounted() {},
	destroyed() {},
}
</script>
<style lang="scss" scoped>
.home {
	// font-size: 16px;
	.home-top-img {
		width: 100%;
		height: 6.76rem;
		margin-bottom: 1.6rem;
	}
	.containerBg {
		width: 100%;
		height: 10.11rem;
		position: absolute;
		top: 17.44rem;
		z-index: -1;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}
	// 三栏都需要浮动
	.center,
	.left,
	.right {
		float: left;
	}
	// 左右两栏固定宽度
	.left,
	.right {
		width: 3.15rem;
		background-color: #fff;
	}
	// 中间栏  因为需要自适应所以设置宽度100%
	.center {
		width: 100%;
	}
	.left {
		margin-left: -100%;
	}
	.right {
		margin-left: -3.15rem;
	}
	.inner {
		margin: 0 3.15rem;
	}
	.center {
		position: relative;
	}
}

//媒体查询
@media screen and (max-width: 1200px) {
	.home {
	}
}
@media screen and (max-width: 992px) {
	.home {
	}
}
@media screen and (max-width: 768px) {
	.home {
		.home-top-img {
			height: 178px;
		}
		.containerBg {
			display: none;
		}
		// 左右两栏固定宽度
		.left,
		.right {
			width: 16px;
		}
		.right {
			margin-left: -16px;
		}
		.inner {
			margin: 0 16px;
		}
	}
}
</style>
