<!-- 首页-体验中心 -->
<template>
	<div class="ExperienceCenter-warp">
		<div class="title">
			<span class="title-text">体验中心</span>
			<span class="title-desc">便捷体验智能科技，快速了解捷途慧声</span>
		</div>
		<div class="ExperienceCenter-center">
			<div
				class="experience"
				v-for="(item, index) in experienceImg"
				:key="index"
				@click="getExperience(item.path, index)"
			>
				<div class="experience-icon">
					<i :class="item.iconClass"></i>
				</div>
				<div class="experience-name">{{ item.name }}</div>
				<div class="experience-sketch">{{ item.sketch }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { homeExperience } from '@/utils/mock.js'
export default {
	name: 'ExperienceCenter',
	data() {
		return {
			experienceImg: homeExperience,
			mobileTerminal: false,
		}
	},
	watch: {},
	created() {},
	mounted() {
		if ((this, this._isMobile())) {
			this.mobileTerminal = true
		} else {
			this.mobileTerminal = false
		}
	},
	methods: {
		getExperience(path, index) {
			this.activeIndex = index
			// let htmlWidth = document.querySelector("html").offsetWidth;
			if (path) {
				!this.mobileTerminal
					? this.$router.push(path)
					: this.$router.push('/experienceDemo')
			}
		},
		_isMobile() {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			)
			return flag
		},
	},
	components: {},
	computed: {
		activeIndex: {
			get() {
				return this.$store.state.activeIndex
			},
			set(val) {
				this.$store.state.activeIndex = val
			},
		},
	},

	destroyed() {},
}
</script>
<style lang="scss" scoped>
.ExperienceCenter-warp {
	margin-bottom: 1.6rem;
	.ExperienceCenter-center {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-auto-flow: row;
		justify-content: space-around;
		.experience {
			width: 2.93rem;
			height: 3.5rem;
			background-color: #ffffff;
			border: 1px solid #e5e8f1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			&-icon {
				width: 0.96rem;
				height: 0.96rem;
				background-color: #eff6ff;
				color: #127bf2;
				border-radius: 50%;
				text-align: center;
				line-height: 0.96rem;
				i {
					font-size: 0.48rem;
					display: inline-block;
				}
			}
			&-name {
				font-size: 0.24rem;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 700;
				color: #485163;
				line-height: 0.32rem;
				margin: 0.3rem 0 0.2rem;
			}
			&-sketch {
				font-size: 0.16rem;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 400;
				color: #485163;
				line-height: 0.24rem;
			}
			&:hover {
				box-shadow: 0 0.28rem 0.58rem 0 rgba(24, 71, 123, 0.09);
				.experience-icon {
					background-color: #127bf2;
					color: #ffffff;
				}
			}
		}
	}
}

.title {
	width: 100%;
	margin-bottom: 0.6rem;
	text-align: center;
	.title-text {
		height: 0.6rem;
		font-size: 0.52rem;
		font-family: Source Han Sans, Source Han Sans CN;
		font-weight: 500;
		color: #333333;
		line-height: 0.6rem;
		display: block;
	}
	.title-desc {
		display: inline-block;
		font-size: 0.24rem;
		font-family: Source Han Sans, Source Han Sans CN;
		font-weight: 400;
		color: #666666;
		line-height: 0.32rem;
		margin-top: 0.12rem;
	}
}

@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 992px) {
}
@media screen and (max-width: 768px) {
	.ExperienceCenter-warp {
		.ExperienceCenter-center {
			grid-template-columns: 1fr 1fr;
			gap: 11px;
			.experience {
				width: 166px;
				height: 166px;
				border-radius: 4px;
				&-icon {
					width: 40px;
					height: 40px;
					line-height: 40px;
					i {
						font-size: 20px;
					}
				}
				&-name {
					font-size: 14px;
					line-height: 22px;
					margin: 12px 0 4px;
				}
				&-sketch {
					font-size: 12px;
					line-height: 20px;
				}
			}
		}
		.title {
			.title-text {
				height: 24px;
				font-size: 16px;
				font-weight: 400;
				color: #020814;
				line-height: 24px;
			}
			.title-desc {
				font-size: 10px;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 400;
				color: #666666;
				line-height: 18px;
			}
		}
	}
}

@media screen and (max-width: 520px) {
}
</style>
