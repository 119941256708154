<!-- 首页-公司介绍 -->
<template>
	<div class="CompanyProfile-warp" v-if="!isMobileEnd">
		<div class="CompanyProfile-warp-left">
			<div class="CompanyProfile-warp-left-name">捷途慧声</div>
			<div class="CompanyProfile-warp-left-sketch">
				国内领先的AI服务解决方案提供商
			</div>
			<div class="CompanyProfile-warp-left-desc">
				<p v-for="(item, index) in companyProfile" :key="index">
					{{ item }}
				</p>
			</div>
			<div class="CompanyProfile-warp-left-data">
				<img
					v-lazy="dataDescUrl"
					:key="dataDescUrl"
					alt="公司介绍数据"
				/>
			</div>
			<el-button
				class="CompanyProfile-warp-left-btn"
				plain
				@click="getCompanyOf"
				>了解我们</el-button
			>
		</div>
		<div class="CompanyProfile-warp-right">
			<img
				v-lazy="illustrationUrl"
				:key="illustrationUrl"
				alt="公司介绍"
			/>
		</div>
	</div>
	<div class="CompanyProfile-768-warp" v-else>
		<div class="CompanyProfile-768-warp-left">
			<div class="CompanyProfile-768-warp-left-name">捷途慧声</div>
			<div class="CompanyProfile-768-warp-left-sketch">
				国内领先的AI服务解决方案提供商
			</div>
			<div class="CompanyProfile-768-warp-left-desc">
				<p v-for="(item, index) in companyProfile" :key="index">
					{{ item }}
				</p>
			</div>
			<div class="CompanyProfile-768-warp-left-btn" @click="getCompanyOf">
				查看更多
			</div>
		</div>
		<div class="CompanyProfile-768-warp-right">
			<img
				v-lazy="illustrationUrl"
				:key="illustrationUrl"
				alt="公司介绍"
			/>
		</div>
		<div class="CompanyProfile-768-warp-data">
			<img v-lazy="dataDescUrl" :key="dataDescUrl" alt="公司介绍数据" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'CompanyProfile',
	data() {
		return {
			illustrationUrl: require('@/assets/img/homeCompanyProfile.png'),
			dataDescUrl: require('@/assets/img/homeCompanyProfileData.png'),
			companyProfile: [
				//公司文字介绍
				'杭州捷途慧声科技有限公司是国内领先的AI服务解决方案提供商。具有多年的人工智能行业从业经验，主要专注于智能语音、智能图像、自然语义理解等人工智能技术的研究与应用。',
				'公司核心技术依托人工智能领域的先进学术研究和强大科研力量，并不断地探索，形成独具特色的研发成果。结合对行业业务的深刻理解，为公安、法院、检察院、纪委监察委、海关等公检法行业用户提供基于人工智能技术的语音识别和图像识别应用的解决方案和产品。',
				// '未来，捷途慧声将秉承“聚焦 创新 执行 分享”的核心价值，坚持“以人为本、技术创新、专注产品、用心服务”的行动准则，以“建设一流人工智能服务商”为愿景，不断开拓创新，为智能化产业发展贡献力量。',
			],
		}
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		getCompanyOf() {
			this.$router.push('/companyOf')
		},
	},
	components: {},
	computed: {
		isMobileEnd() {
			return this.$store.state.isMobileEnd
		},
	},

	destroyed() {},
}
</script>
<style lang="scss" scoped>
.CompanyProfile-warp {
	width: 100%;
	height: 8.24rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	&-left {
		width: calc(100% - 6.25rem);
		height: 5.04rem;
		text-align: left;
		&-name {
			font-size: 0.52rem;
			font-family: Source Han Sans, Source Han Sans CN;
			font-weight: 700;
			color: #333333;
			line-height: 1;
			-webkit-background-clip: text;
		}
		&-sketch {
			font-size: 0.24rem;
			font-family: Source Han Sans, Source Han Sans CN;
			font-weight: 400;
			color: #333333;
			line-height: 1;
			margin: 0.28rem 0 0.4rem;
		}
		&-desc {
			p {
				font-size: 0.14rem;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 400;
				color: #666666;
				line-height: 0.22rem;
				margin-bottom: 0.16rem;
			}
		}
		&-data {
			width: 5.6rem;
			height: 1.08rem;
			margin: 0.4rem 0;
			img {
				object-fit: initial;
			}
		}
		&-btn {
			width: 1.6rem;
			height: 0.4rem;
			background-color: #127bf2;
			color: #ffffff;
			border-radius: 44px 44px 44px 44px;
			font-size: 0.14rem;
			font-family: Source Han Sans, Source Han Sans CN;
			font-weight: 400;
			line-height: 1;
			letter-spacing: 1px;
			border: 1px solid transparent;
			&:hover {
				color: #ffffff;
				background-color: #55a4ff;
			}
		}
	}
	&-right {
		width: 6.25rem;
		height: 4.8rem;
	}
}
.CompanyProfile-768-warp {
	width: 100%;
	height: 660px;
	margin-bottom: 24px;
	&-left {
		width: calc(100% - 24px);
		height: 274px;
		padding: 12px;
		text-align: left;
		background-color: #f7f8fa;
		position: relative;
		&-name {
			font-size: 16px;
			font-family: Source Han Sans, Source Han Sans CN;
			font-weight: 700;
			color: #333333;
			line-height: 24px;
		}
		&-sketch {
			font-size: 12px;
			font-family: Source Han Sans, Source Han Sans CN;
			font-weight: 400;
			color: #333333;
			line-height: 20px;
			margin-bottom: 8px;
		}
		&-desc {
			p {
				font-size: 12px;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 400;
				color: #8a8a8a;
				line-height: 22px;
			}
		}
		&-btn {
			font-size: 12px;
			font-family: Source Han Sans, Source Han Sans CN;
			font-weight: 400;
			color: #127bf2;
			line-height: 12px;
			position: absolute;
			right: 12px;
			bottom: 12px;
		}
	}
	&-right {
		width: 100%;
		height: 262px;
		margin: 12px 0 20px;
	}
	&-data {
		width: 100%;
		height: 68px;
	}
}

@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 992px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 520px) {
}
</style>
