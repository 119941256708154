var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"productCenter-warp"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"title-text"},[_vm._v("产品中心")]),_c('div',{staticClass:"pagination"},_vm._l((_vm.pageBar),function(number,i){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.pageBar > 1),expression:"pageBar > 1"}],key:i,staticClass:"page",class:{ active: _vm.params.page == number },on:{"click":function($event){return _vm.handlePage(number)},"mouseenter":function($event){return _vm.handlePage(number)}}})}),0)]),_c('div',{staticClass:"productCenter-warp-center"},[_c('div',{staticClass:"index__container--Bem-H"},_vm._l((_vm.productInfo),function(item,index){return _c('div',{key:index,staticClass:"index__box--349II delay500 animate__fadeInRight animate__animated",class:[
					`delay${index + 5}00`,
					{
						'index__boxUnactive--2YAdd': index != _vm.currentIndex,
					},
				],on:{"mouseenter":function($event){return _vm.enterDiscern(index)},"click":function($event){return _vm.clickProduct(item.id)}}},[_c('img',{staticClass:"index__boxBg--2lQK_",attrs:{"src":index == _vm.currentIndex
							? item.selectedCoverImg
							: item.unselectedCoverImg}}),_c('img',{staticClass:"index__preload--1ZAHH",attrs:{"src":item.selectedCoverImg}}),_c('img',{staticClass:"index__preload--1ZAHH",attrs:{"src":item.unselectedCoverImg}}),_c('div',{staticClass:"index__title--hjj4T",class:{
						'index__titleActive--3olpv': index != _vm.currentIndex,
					}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(index == _vm.currentIndex),expression:"index == currentIndex"}],staticClass:"index__bar--LiQW9 animate__fadeInLeft animate__animated"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(index == _vm.currentIndex),expression:"index == currentIndex"}],staticClass:"index__desc--35UYB animate__fadeInUp animate__animated"},[_vm._v(" "+_vm._s(item.sketch)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(index == _vm.currentIndex),expression:"index == currentIndex"}],staticClass:"index__learnMore--1DIZ6 animate__fadeIn animate__animated",on:{"click":function($event){return _vm.clickProduct(item.id)}}},[_vm._v(" 了解更多 ")])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }