<!-- 首页-产品中心 -->
<template>
	<div class="productCenter-warp">
		<div class="title">
			<span class="title-text">产品中心</span>
			<div class="pagination">
				<div
					class="page"
					v-show="pageBar > 1"
					:class="{ active: params.page == number }"
					v-for="(number, i) in pageBar"
					:key="i"
					@click="handlePage(number)"
					@mouseenter="handlePage(number)"
				></div>
			</div>
		</div>
		<div class="productCenter-warp-center">
			<div class="index__container--Bem-H">
				<div
					class="index__box--349II delay500 animate__fadeInRight animate__animated"
					v-for="(item, index) in productInfo"
					:key="index"
					:class="[
						`delay${index + 5}00`,
						{
							'index__boxUnactive--2YAdd': index != currentIndex,
						},
					]"
					@mouseenter="enterDiscern(index)"
					@click="clickProduct(item.id)"
				>
					<img
						class="index__boxBg--2lQK_"
						:src="
							index == currentIndex
								? item.selectedCoverImg
								: item.unselectedCoverImg
						"
					/><img
						class="index__preload--1ZAHH"
						:src="item.selectedCoverImg"
					/><img
						class="index__preload--1ZAHH"
						:src="item.unselectedCoverImg"
					/>
					<div
						class="index__title--hjj4T"
						:class="{
							'index__titleActive--3olpv': index != currentIndex,
						}"
					>
						{{ item.name }}
					</div>
					<div
						v-show="index == currentIndex"
						class="index__bar--LiQW9 animate__fadeInLeft animate__animated"
					></div>
					<div
						v-show="index == currentIndex"
						class="index__desc--35UYB animate__fadeInUp animate__animated"
					>
						{{ item.sketch }}
					</div>
					<div
						v-show="index == currentIndex"
						class="index__learnMore--1DIZ6 animate__fadeIn animate__animated"
						@click="clickProduct(item.id)"
					>
						了解更多
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getHomeProductList } from '@/network/home/index'
export default {
	name: 'ProductCenter',
	data() {
		return {
			currentIndex: 1,
			productInfo: null,
			pageBar: 1,
			params: {
				page: 1,
				pageNum: 6,
			},
			total: 6,
		}
	},
	watch: {},
	created() {
		if (window.screen.width <= 768) {
			this.params.pageNum = 3
		}
	},
	mounted() {
		this._getHomeProductList()
	},
	methods: {
		clickProduct(id) {
			if (id) {
				this.$router.push({
					path: '/product',
					query: {
						id,
					},
				})
			}
		},
		//点击页码
		handlePage(number) {
			this.params.page = number
			this._getHomeProductList()
		},
		_getHomeProductList() {
			getHomeProductList(this.params).then((res) => {
				if (res.data.code == 200) {
					const { total, list } = res.data.data
					this.pageBar = Math.ceil(total / 6)
					this.productInfo = list.map((item) => {
						let obj = Object.assign(item, {
							selectedCoverImg:
								window.baseUrl + item.selectedCoverImg,
							unselectedCoverImg:
								window.baseUrl + item.unselectedCoverImg,
						})
						return obj
					})
					this.total = total
				}
			})
		},
		//鼠标靠近左上角改变文字
		enterDiscern(index) {
			// 记录活跃态音频索引，以及活跃态文字
			this.currentIndex = index
		},
	},
	components: {},
	computed: {},
	destroyed() {},
}
</script>
<style lang="scss" scoped>
@import './product.css';
</style>
<style lang="scss" scoped>
.productCenter-warp {
	margin-bottom: 1.6rem;
	&-center {
		min-height: 3.2rem;
	}
}
.title {
	width: 100%;
	margin-bottom: 0.6rem;
	text-align: center;
	.title-text {
		height: 0.6rem;
		font-size: 0.52rem;
		font-family: Source Han Sans, Source Han Sans CN;
		font-weight: 500;
		color: #333333;
		line-height: 0.6rem;
		display: block;
	}
	.pagination {
		height: 0.04rem;
		margin-top: 0.12rem;
		display: flex;
		justify-content: center;
		.page {
			width: 0.32rem;
			height: 0.04rem;
			background: #dfdddd;
			margin: 0 0.04rem;
			cursor: pointer;
			:hover {
				background: #127bf2;
			}
		}
		.active {
			background: #127bf2;
		}
	}
}
@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 992px) {
}
@media screen and (max-width: 768px) {
	.index__container--Bem-H .index__box--349II.index__boxUnactive--2YAdd {
		width: 108px;
	}
	.index__container--Bem-H .index__box--349II {
		height: 102px;
		width: 108px;
	}
	.index__container--Bem-H .index__box--349II .index__boxBg--2lQK_ {
		height: 102px;
	}
	.index__container--Bem-H
		.index__box--349II
		.index__title--hjj4T.index__titleActive--3olpv {
		font-size: 14px;
		font-family: Source Han Sans, Source Han Sans CN;
		font-weight: 500;
		line-height: 18px;
	}
	.index__container--Bem-H .index__box--349II .index__title--hjj4T {
		font-size: 14px;
		font-family: Source Han Sans, Source Han Sans CN;
		font-weight: 500;
		line-height: 18px;
	}
	.index__container--Bem-H .index__box--349II .index__bar--LiQW9 {
		display: none;
	}
	.index__container--Bem-H .index__box--349II .index__desc--35UYB {
		display: none;
	}
	.index__container--Bem-H .index__box--349II .index__learnMore--1DIZ6 {
		width: 92px;
		height: 20px;
		font-size: 12px;
		line-height: 20px;
	}
	.title {
		.title-text {
			height: 24px;
			font-size: 16px;
			font-weight: 400;
			color: #020814;
			line-height: 24px;
		}
		.pagination {
			.page {
				width: 8px;
				margin: 0 3px;
			}
		}
	}
}
@media screen and (max-width: 520px) {
}
</style>
