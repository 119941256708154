<!-- 首页-行业解决方案 -->
<template>
	<div class="IndustrySolutions-warp">
		<div class="title">
			<span class="title-text">行业解决方案</span>
			<div class="pagination">
				<div
					class="page"
					v-show="pageBar > 1"
					:class="{ active: params.page == number }"
					v-for="(number, i) in pageBar"
					:key="i"
					@click="handlePage(number)"
					@mouseenter="handlePage(number)"
				></div>
			</div>
		</div>
		<div class="industrySolutions">
			<div
				class="solution"
				v-for="(item, index) in solutionList"
				:key="index"
			>
				<div class="solution-A">
					<div class="solution-A-center">
						<div class="solution-A-center-img">
							<img
								v-lazy="item.coverImg"
								:key="item.coverImg"
								alt="方案"
							/>
						</div>
						<div class="solution-A-center-briefName">
							{{ item.briefName }}
						</div>
						<div class="solution-A-center-sketch">
							{{ item.sketch }}
						</div>
					</div>
				</div>
				<div class="solution-B">
					<div
						class="solution-B-center"
						@click="handleSolution(item.id)"
					>
						<div class="solution-B-center-bg">
							<img :src="hoverImg" alt="方案详情bg" />
						</div>
						<div class="solution-B-center-name">
							{{ item.name }}
						</div>
						<div class="solution-B-center-sketch">
							{{ item.sketch }}
						</div>
						<el-button
							class="solution-B-center-btn"
							plain
							@click="handleSolution(item.id)"
							>了解详情</el-button
						>
					</div>
				</div>
			</div>
			<!-- <div class="solution">
          <div class="solution-G"><p>期待更多解决方案</p></div>
        </div> -->
		</div>
	</div>
</template>

<script>
import { getHomePlanList } from '@/network/home/index'
import { homeSolution } from '@/utils/mock.js'
export default {
	name: 'IndustrySolutions',
	data() {
		return {
			hoverImg: require('@/assets/img/featureHover.png'),
			solutionList: homeSolution,
			pageBar: 1,
			params: {
				page: 1,
				pageNum: 10,
			},
			total: 10,
		}
	},
	watch: {},
	created() {},
	mounted() {
		this._getHomePlanList()
	},
	methods: {
		handleSolution(id) {
			//之后用id
			if (id) {
				this.$router.push({
					path: '/solution',
					query: {
						id,
					},
				})
			}
		},
		//点击页码
		handlePage(number) {
			this.params.page = number
			this._getHomePlanList()
		},
		_getHomePlanList() {
			getHomePlanList(this.params).then((res) => {
				if (res.data.code == 200) {
					const { total, list } = res.data.data
					this.pageBar = Math.ceil(total / 10)
					// "list": [
					//     {
					//         "id": "string",
					//         "coverImg": "string",
					//         "name": "string",
					//         "briefName": "string",
					//         "sketch": "string"
					//     }
					// ]
					this.solutionList = list.map((item) => {
						let obj = Object.assign(item, {
							coverImg: window.baseUrl + item.coverImg,
						})
						return obj
					})
					this.total = total
				}
			})
		},
	},
	components: {},
	computed: {},
	destroyed() {},
}
</script>
<style lang="scss" scoped>
.IndustrySolutions-warp {
	margin-bottom: 1.6rem;
	.industrySolutions {
		width: 100%;
		min-height: 5.24rem;
		box-shadow: 0 4px 10px 0px rgba(0, 0, 0, 0.13);
		border-radius: 0.1rem;
		overflow: hidden;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		grid-auto-flow: row;
		justify-content: space-around;
		.solution {
			width: 2.54rem;
			height: 2.6rem;
			background: #ffffff;
			border: 1px solid #f5f6f9;
			overflow: hidden;
			.solution-A {
				display: block;
				width: 100%;
				height: 100%;
				transform: translateX(0);
				-webkit-transition: all 0.5s ease-in-out;
				transition: all 0.5s ease-in-out;
				.solution-A-center {
					width: calc(100% - 0.6rem);
					height: calc(100% - 0.8rem);
					padding: 0.4rem 0.3rem;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					&-img {
						width: 0.75rem;
						height: 0.75rem;
						margin-bottom: 0.2rem;
					}
					&-briefName {
						font-size: 0.18rem;
						font-family: Source Han Sans, Source Han Sans CN;
						font-weight: 500;
						color: #485163;
						line-height: 0.26rem;
						margin-bottom: 0.12rem;
					}
					&-sketch {
						height: 0.44rem;
						font-size: 0.14rem;
						font-family: Source Han Sans, Source Han Sans CN;
						font-weight: 400;
						color: #999999;
						line-height: 0.22rem;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}
				}
			}
			.solution-B {
				width: 100%;
				height: 100%;
				background: linear-gradient(316deg, #127bf2 0%, #82bbfc 100%);
				transform: translateX(100%);
				-webkit-transition: all 0.5s ease-in-out;
				transition: all 0.5s ease-in-out;
				.solution-B-center {
					width: 100%;
					height: 100%;
					padding: 0.22rem;
					box-sizing: border-box;
					position: relative;
					&-bg {
						width: 1.1rem;
						height: 0.95rem;
						position: absolute;
						top: 0;
						right: 0;
					}
					&-name {
						font-size: 0.18rem;
						font-family: Source Han Sans, Source Han Sans CN;
						font-weight: 500;
						line-height: 0.26rem;
						color: #ffffff;
						margin-top: 0.24rem;
						margin-bottom: 0.2rem;
					}
					&-sketch {
						// width: 100%;
						height: 0.75rem;
						font-size: 0.14rem;
						font-family: Source Han Sans, Source Han Sans CN;
						font-weight: 400;
						color: #ffffff;
						line-height: 0.22rem;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
					}
					&-btn {
						width: 1.32rem;
						height: 0.4rem;
						background-color: #ffffff;
						color: #127bf2;
						border-radius: 0.02rem;
						font-size: 0.18rem;
						font-family: Source Han Sans, Source Han Sans CN;
						font-weight: 500;
						line-height: 1;
						letter-spacing: 1px;
						border: 1px solid transparent;
						position: absolute;
						right: 0.22rem;
						bottom: 0.22rem;
					}
				}
			}
			&:hover {
				.solution-A {
					// animation-name: seamless-solution-A; // Q3
					// animation-timing-function: linear; // 动画速度曲线，匀速
					// animation-duration: 3s;
					transform: translateX(-100%);
					display: none;
				}
				.solution-B {
					transform: translateX(0);
					// display: block;
					// animation-name: seamless-solution-B; // Q4
					// animation-duration: 3s;
				}
			}
			.solution-G {
				width: 100%;
				height: 100%;
				background: #ccc;
				display: flex;
				justify-content: center;
				align-items: center;
				p {
					font-size: 0.2rem;
					font-family: Source Han Sans, Source Han Sans CN;
					// font-weight: bold;
					line-height: 130%;
					// color: #394551;
					color: #666;
					cursor: default;
				}
			}
		}
	}
}

.title {
	width: 100%;
	margin-bottom: 0.6rem;
	text-align: center;
	.title-text {
		height: 0.6rem;
		font-size: 0.52rem;
		font-family: Source Han Sans, Source Han Sans CN;
		font-weight: 500;
		color: #333333;
		line-height: 0.6rem;
		display: block;
	}
	.pagination {
		height: 0.04rem;
		margin-top: 0.12rem;
		display: flex;
		justify-content: center;
		.page {
			width: 0.32rem;
			height: 0.04rem;
			background: #dfdddd;
			margin: 0 0.04rem;
			cursor: pointer;
			:hover {
				background: #127bf2;
			}
		}
		.active {
			background: #127bf2;
		}
	}
}

@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 992px) {
}
@media screen and (max-width: 768px) {
	.IndustrySolutions-warp {
		.industrySolutions {
			box-shadow: none;
			border: none;
			grid-template-columns: 1fr 1fr;
			gap: 8px;
			.solution {
				width: 165px;
				height: 102px;
				border-radius: 4px;
				border: solid 1px #e6e7ee;
				.solution-A {
					.solution-A-center {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						&-img {
							width: 40px;
							height: 40px;
							margin-bottom: 4px;
						}
						&-briefName {
							height: 22px;
							font-size: 14px;
							font-family: Source Han Sans, Source Han Sans CN;
							font-weight: 500;
							color: #485163;
							line-height: 22px;
						}
						&-sketch {
							display: none;
						}
					}
				}
				.solution-B {
					.solution-B-center {
						width: 100%;
						height: 100%;
						padding: 12px;
						&-bg {
							width: 62px;
							height: 62px;
							opacity: 0.55;
						}
						&-name {
							font-size: 14px;
							line-height: 22px;
							margin-bottom: 8px;
						}
						&-sketch {
							height: 40px;
							font-size: 12px;
							line-height: 20px;
							-webkit-line-clamp: 2;
						}
						&-btn {
							display: none;
						}
					}
				}
			}
		}
		.title {
			.title-text {
				height: 24px;
				font-size: 16px;
				font-weight: 400;
				color: #020814;
				line-height: 24px;
			}
			.pagination {
				display: none;
			}
		}
	}
}
@media screen and (max-width: 520px) {
}
</style>
